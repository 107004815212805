





















































@import '~@/assets/styles/helpers/_variables.scss';

.v-btn {
  color: $colorPrimary;
  align-self: center;
  font-size: 16px;
  text-transform: uppercase;

  &-img {
    margin: 16px;
    height: 24px;
  }
}
